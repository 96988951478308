import './App.css';

import HelloWorld from "./components/HelloWorld.js";

function App() {
  return (
    <div>
      <header>
        
       <h1>TEstando</h1>
        <h2>tirando uma dúvida 2</h2>
       <HelloWorld />
      </header>
    </div>
  );
}

export default App;
